import actions from './actions'

const initialState = {
  staffInfo: null,
  userInfodata: null,
  vendorInfoData: null,
  data: null,
  roledata: null,
  selectedData: null,
  newlyAdded: null,

  search: null,
  company_data: null,

  displayAddDrawer: false,
  creating: false,

  displayUpdateDrawer: false,
  updating: false,

  displayRemoveModal: false,
  removing: false,
}

export default function staffReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ADD_DATA:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      }

    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.staffInfo, displayUpdateDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDrawer: false }

    case actions.REMOVE_DATA:
      return {
        ...state,
        displayRemoveModal: false,
        data: state.data.filter(o => action.payload._id !== o._id),
      }
    case actions.SHOW_CREATE:
      return { ...state, displayAddDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddDrawer: false }

    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.staffInfo, displayRemoveModal: true }
    case actions.HIDE_DELETE:
      return { ...state, displayRemoveModal: false }
    default:
      return state
  }
}

import actions from './actions'

const initialState = {
  vendorlistdata: null,
  userInfodata: null,
  vendorInfoData: null,
  data: null,
  roledata: null,
  selectedData: null,
  newlyAdded: null,
  search: null,
  company_data: null,
  displayAddVendorDrawer: false,
  displayUpdateVendorDrawer: false,
  creating: false,
}

export default function vendorReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddVendorDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddVendorDrawer: false }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.vendor, displayUpdateVendorDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateVendorDrawer: false }

    default:
      return state
  }
}

import apiClient from 'services/axios'

const BASE_URL = 'WebOrders'

export async function loadorders(payload) {
  return apiClient
    .get(`${BASE_URL}/loadorderlist`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getorderdetails(payload) {
  return apiClient
    .get(`${BASE_URL}/getorderdetailinfo/${payload.orderIdVal}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changestatus(payload) {
  return apiClient
    .post(`${BASE_URL}/updatestatusinfo/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function pdfdownload(payload) {
  console.log('pdfdownload', payload)
  return apiClient
    .post(`${BASE_URL}/exportpdfdownload2/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function pdfdownload3(payload) {
  console.log('pdfdownload', payload)
  return apiClient
    .post(`${BASE_URL}/exportpdfdownload3/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

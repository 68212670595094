const actions = {
  SET_STATE: 'products/SET_STATE',
  SHOW_CREATE: 'products/SHOW_CREATE',
  HIDE_CREATE: 'products/HIDE_CREATE',

  LOAD_PRODUCTS_LIST: 'products/LOAD_PRODUCTS_LIST',
  ADD_PRODUCT_NAME: 'products/ADD_PRODUCT_NAME',
}

export default actions

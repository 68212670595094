const actions = {
  SET_STATE: 'sliders/SET_STATE',
  LOAD_SLIDERS_LIST: 'sliders/LOAD_SLIDERS_LIST',
  CHANGE_SLIDER_IMAGE: 'sliders/CHANGE_SLIDER_IMAGE',
  SHOW_POPUP: 'sliders/SHOW_POPUP',
  HIDE_POPUP: 'sliders/HIDE_POPUP',
  DELETEiMAGE: 'sliders/DELETEiMAGE',
  SHOW_DELETE_IMAGE: 'sliders/SHOW_DELETE_IMAGE',
  HIDE_DELETE_CREATE: 'sliders/HIDE_DELETE_CREATE',
  SHOW_CREATE: 'sliders/SHOW_CREATE',
  HIDE_CREATE: 'sliders/HIDE_CREATE',
  ADDSLIDERiMAGE: 'sliders/ADDSLIDERiMAGE',
  UPDATE_SLIDER_STATUS: 'sliders/UPDATE_SLIDER_STATUS',

}

export default actions

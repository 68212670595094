import actions from './actions'

const initialState = {
  orderdownload: null,
  invoiceInfo: null,
  data: null,
  selectedData: null,
  search: null,
  displayCreateBillDrawer: false,
  displayOrderDetailDrawer: false,
  creating: false,
}

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayCreateBillDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayCreateBillDrawer: false }
    case actions.SHOW_ORDER_DETAILS:
      return { ...state, selectedData: action.payload.orderdetail, displayOrderDetailDrawer: true }
    case actions.HIDE_ORDER_DETAILS:
      return { ...state, selectedData: null, displayOrderDetailDrawer: false }

    default:
      return state
  }
}

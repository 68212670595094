import apiClient from 'services/axios'

const BASE_URL = 'ProductManagement'

export async function loadorders(payload) {
  return apiClient
    .get(`${BASE_URL}/loadweborderlist`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createBill(payload) {
  return apiClient
    .post(`${BASE_URL}/createbill`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

import apiClient from 'services/axios'

const BASE_URL = 'WebProductsInfo'

export async function loadproductsinfo() {
  return apiClient
    .get(`${BASE_URL}/getproductsinfo`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadfilter(payload) {
  return apiClient
    .post(`${BASE_URL}/getfilter`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatestock(payload) {
  return apiClient
    .post(`${BASE_URL}/stockupdate`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveimages(payload) {
  return apiClient
    .post(`${BASE_URL}/saveproductimages`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadproductimages(payload) {
  return apiClient
    .post(`${BASE_URL}/getproductimages`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadcategoryfromvenprod() {
  return apiClient
    .get(`${BASE_URL}/loadcategoryfromvenprod`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadproductmaster() {
  return apiClient
    .get(`${BASE_URL}/loadproductmaster`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadproductname(payload) {
  return apiClient
    .put(`${BASE_URL}/getproductname/${payload.categoryId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function addproduct(payload) {
  return apiClient
    .post(`${BASE_URL}/saveproduct/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateproduct(payload) {
  return apiClient
    .post(`${BASE_URL}/editproduct/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changeproductimage(payload) {
  return apiClient
    .post(`${BASE_URL}/updateproductimageinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateimagestatus(payload) {
  return apiClient
    .post(`${BASE_URL}/updateimagestatus`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteimage(payload) {
  return apiClient
    .post(`${BASE_URL}/deleteproductimage`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

import actions from './actions'

const initialState = {
  vendorlistdata: null,
  categorylistdata: null,
  categorynamedata: null,
  userInfodata: null,
  vendorInfoData: null,
  data: null,
  roledata: null,
  selectedData: null,
  newlyAdded: null,
  search: null,
  company_data: null,
  displayAddCategoryDrawer: false,
  displayUpdateCategoryDrawer: false,
  creating: false,
}

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddCategoryDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddCategoryDrawer: false }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.category, displayUpdateCategoryDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateCategoryDrawer: false }

    default:
      return state
  }
}

const actions = {
  SET_STATE: 'vendor/SET_STATE',
  REMOVE_DATA: 'vendor/REMOVE_DATA',
  SHOW_CREATE: 'vendor/SHOW_CREATE',
  HIDE_CREATE: 'vendor/HIDE_CREATE',
  HIDE_UPDATE: 'vendor/HIDE_UPDATE',
  SHOW_UPDATE: 'vendor/SHOW_UPDATE',


  LOAD_VENDOR_INFO: 'vendor/LOAD_VENDOR_INFO',
  ADD_VENDOR: 'vendor/ADD_VENDOR',
  UPDATE_VENDOR: 'vendor/UPDATE_VENDOR',

}

export default actions

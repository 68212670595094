import apiClient from 'services/axios'

const BASE_URL = 'WebVendor'
const BASE_URL2 = 'WebOrders'
const BASE_URL3 = 'WebCategory'
const BASE_URL4 = 'WebProducts'

export async function loadvendor() {
  return apiClient
    .get(`${BASE_URL}/getvendorinfo`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadordersreport(payload) {
  return apiClient
    .get(
      `${BASE_URL2}/getordersreport/${payload.startDate}/${payload.endDate}/${payload.orderType}/${payload.reportFor}`,
    )
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadcategoryreport(payload) {
  return apiClient
    .get(`${BASE_URL3}/getcategoryreport/${payload.reportFor}/${payload.status}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadproductsreport(payload) {
  return apiClient
    .get(
      `${BASE_URL4}/getproductsreport/${payload.categoryID}/${payload.reportFor}/${payload.status}`,
    )
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

const actions = {
  SET_STATE: 'invoice/SET_STATE',

  LOAD_ALL: 'invoice/LOAD_ALL',
  CREATE_INVOICE_ITEM: 'invoice/CREATE_INVOICE_ITEM',
  SHOW_ORDER_DETAILS: 'invoice/SHOW_ORDER_DETAILS',
  HIDE_ORDER_DETAILS: 'invoice/HIDE_ORDER_DETAILS',
  GET_ORDER_DETAILS: 'invoice/GET_ORDER_DETAILS',
  EXPORT_PDF_DOWNLOAD: 'invoice/EXPORT_PDF_DOWNLOAD',

  SHOW_CREATE: 'invoice/SHOW_CREATE',
  HIDE_CREATE: 'invoice/HIDE_CREATE',
  LOAD_ALL_ORDERS: 'invoice/LOAD_ALL_ORDERS',
}

export default actions

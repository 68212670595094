import apiClient from 'services/axios'

const BASE_URL = 'WebAdmin'

export async function login(payload) {
  return apiClient
    .post(`${BASE_URL}/check_login`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changepassword(payload) {
  return apiClient
    .post(`${BASE_URL}/change`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reset(payload) {
  return apiClient
    .post(`${BASE_URL}/reset`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

import { all, call, put, takeLatest } from 'redux-saga/effects'
import { createBill, loadorders } from 'api/invoice'
import { getorderdetails, pdfdownload3 } from 'api/orders'

import actions from './actions'

export function* LOAD_ALL_ORDERS() {
  const { response } = yield call(loadorders)
  if (response) {
    const { orderslist } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        orderlistdata: orderslist,
      },
    })
  }
}

export function* EXPORT_PDF_DOWNLOAD({ payload }) {
  const { response } = yield call(pdfdownload3, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        orderdownload: response?.data?.orderdownload,
        displayOrderDetailDrawer: false,
      },
    })
    yield call(LOAD_ALL_ORDERS)
  }
}

export function* CREATE_INVOICE_ITEM({ payload }) {
  const { response } = yield call(createBill, payload)
  if (response) {
    const { invoiceInfoData } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoiceInfo: invoiceInfoData,
        displayCreateBillDrawer: false,
      },
    })
    yield call(LOAD_ALL_ORDERS)
  }
}

export function* GET_ORDER_DETAILS({ payload }) {
  const { response } = yield call(getorderdetails, payload)
  if (response) {
    const { orderdetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        orderdetailinfo: orderdetail,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL_ORDERS, LOAD_ALL_ORDERS),
    takeLatest(actions.CREATE_INVOICE_ITEM, CREATE_INVOICE_ITEM),
    takeLatest(actions.GET_ORDER_DETAILS, GET_ORDER_DETAILS),
    takeLatest(actions.EXPORT_PDF_DOWNLOAD, EXPORT_PDF_DOWNLOAD),
  ])
}

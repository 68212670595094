import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadcustomer } from 'api/customer'
import actions from './actions'

export function* LOAD_ALL_CUSTOMER() {
  const { response } = yield call(loadcustomer)
  if (response) {
    const { getcustomerinfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        customerlistdata: getcustomerinfo,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL_CUSTOMER, LOAD_ALL_CUSTOMER),
  ])
}

import React from 'react'
import { injectIntl } from 'react-intl'
import { Button, Form, Input, Modal } from 'antd'

const ChangePasswordModal = ({ visible, onSubmit, onCancel, loading, intl: { formatMessage } }) => {
  const [form] = Form.useForm()

  return (
    <Modal
      title={formatMessage({ id: 'text.ChangePassword' })}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" hideRequiredMark onFinish={onSubmit}>
        <Form.Item
          name="oldpassword"
          label={formatMessage({ id: 'form.label.oldpassword' })}
          rules={[
            { required: true, message: formatMessage({ id: 'form.error.password.required' }) },
            { min: 8, message: formatMessage({ id: 'form.error.password.min' }) },
            { max: 32, message: formatMessage({ id: 'form.error.password.max' }) },
          ]}
        >
          <Input.Password
            size="large"
            placeholder={`${formatMessage({ id: 'form.placeholder.oldpassword' })}`}
            maxLength={32}
          />
        </Form.Item>
        <Form.Item
          name="newpassword"
          label={formatMessage({ id: 'form.label.newpassword' })}
          rules={[
            { required: true, message: formatMessage({ id: 'form.error.password.required' }) },
            { min: 8, message: formatMessage({ id: 'form.error.password.min' }) },
            { max: 32, message: formatMessage({ id: 'form.error.password.max' }) },
          ]}
        >
          <Input.Password
            size="large"
            placeholder={`${formatMessage({ id: 'form.placeholder.newpassword' })}`}
            maxLength={32}
          />
        </Form.Item>
        <Form.Item
          name="newconfirmpassword"
          rules={[
            { required: true, message: formatMessage({ id: 'form.error.password.required' }) },
            { min: 8, message: formatMessage({ id: 'form.error.password.min' }) },
            { max: 32, message: formatMessage({ id: 'form.error.password.max' }) },
          ]}
        >
          <Input.Password
            size="large"
            placeholder={`${formatMessage({ id: 'form.placeholder.repeatnewpassword' })}`}
            maxLength={32}
          />
        </Form.Item>
        <Form.Item className="text-right">
          <Button type="primary" htmlType="submit" size="large" loading={loading}>
            {formatMessage({ id: 'action.change' })}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default injectIntl(ChangePasswordModal)

import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { history } from 'index'
import { login, changepassword } from 'api/user'
import store from 'store'
import RootAction from 'redux/actions'
import actions from './actions'
import menuactions from '../menu/actions'
import { userStateData } from './reducers'

export function* LOGIN({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: true,
    },
  })
  const { response } = yield call(login, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: false,
    },
  })
  if (response) {
    yield call(LOGIN_USER, response)
  }
}

function* LOGIN_USER(response) {
  const accessToken = response?.data?.token
  const userName = response?.data?.username
  const name = response?.data?.name
  const userRole = response?.data?.role
  const lastLogin = response?.data?.lastlogin
  const userId = response?.data?.userId
  const vendorname = response?.data?.vendorname
  store.set(`user.accessToken`, response?.data?.token)
  store.set(`user.userName`, response?.data?.username)
  store.set(`user.userRole`, response?.data?.role)
  store.set(`user.lastLogin`, response?.data?.lastlogin)
  store.set(`user.userId`, response?.data?.userId)
  store.set(`user.name`, response?.data?.name)
  store.set(`user.vendorname`, response?.data?.vendorname)
  yield put({
    type: actions.SET_STATE,
    payload: {
      accessToken,
      userName,
      userRole,
      lastLogin,
      userId,
      name,
      vendorname,
    },
  })
  yield put({
    type: menuactions.GET_DATA,
  })
  yield history.push('/')
}

export function* LOGOUT() {
  yield call(CLEAR_USER)
}

export function* CLEAR_USER() {
  store.clearAll()
  yield put({
    type: RootAction.CLEAR_STATE,
  })
  yield put({
    type: actions.SET_STATE,
    payload: userStateData,
  })
  yield history.push('/')
}

export function* CHANGE_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(changepassword, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CHANGE_PASSWORD,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeLatest(actions.CLEAR_USER, CLEAR_USER),
    takeLatest(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
  ])
}

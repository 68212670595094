import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadcategory,
  addcategory,
  getcategory,
  updatecategory,
  changecategoryimage,
  getcategorypdf,
} from 'api/category'
import actions from './actions'

export function* LOAD_ALL_CATEGORY() {
  const { response } = yield call(loadcategory)
  if (response) {
    const { categoryinfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        categorylistdata: categoryinfo,
      },
    })
  }
}

export function* GET_CATEGORY({ payload }) {
  const { response } = yield call(getcategory, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        categorynamedata: response?.data?.categorynamedata,
      },
    })
  }
}

export function* EXPORTPDF() {
  const { response } = yield call(getcategorypdf)
  if (response) {
    console.log('response', response)
  }
}

export function* ADD_CATEGORY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(addcategory, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    const { categoryinfo } = response.data
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        categorylistdata: categoryinfo,
      },
    })
  }
}

export function* UPDATE_CATEGORY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updatecategory, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { categoryinfo } = response.data
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        categorylistdata: categoryinfo,
      },
    })
  }
}

export function* CHANGE_CATEGORY_IMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(changecategoryimage, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { categoryinfo } = response.data
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        categorylistdata: categoryinfo,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.EXPORTPDF, EXPORTPDF),
    takeLatest(actions.LOAD_ALL_CATEGORY, LOAD_ALL_CATEGORY),
    takeLatest(actions.ADD_CATEGORY, ADD_CATEGORY),
    takeLatest(actions.UPDATE_CATEGORY, UPDATE_CATEGORY),
    takeLatest(actions.CHANGE_CATEGORY_IMAGE, CHANGE_CATEGORY_IMAGE),
    takeLatest(actions.GET_CATEGORY, GET_CATEGORY),
  ])
}

const actions = {
  SET_STATE: 'productinfo/SET_STATE',
  LOAD_ALL_PRODUCTS: 'productinfo/LOAD_ALL_PRODUCTS',
  LOAD_CATEGORY_PRODUCTS: 'productinfo/LOAD_CATEGORY_PRODUCTS',
  SHOW_CREATE: 'productinfo/SHOW_CREATE',
  HIDE_CREATE: 'productinfo/HIDE_CREATE',
  LOAD_PRODUCT_MASTER: 'productinfo/LOAD_PRODUCT_MASTER',
  GETPRODUCTNAME: 'productinfo/GETPRODUCTNAME',
  ADD_PRODUCTS: 'productinfo/ADD_PRODUCTS',
  SHOW_UPDATE: 'productinfo/SHOW_UPDATE',
  HIDE_UPDATE: 'productinfo/HIDE_UPDATE',
  UPDATE_PRODUCTS: 'productinfo/UPDATE_PRODUCTS',
  CHANGE_PRODUCT_IMAGE: 'productinfo/CHANGE_PRODUCT_IMAGE',
  FILTER: 'productinfo/FILTER',
  SHOW_STOCK_MODAL: 'productinfo/SHOW_STOCK_MODAL',
  HIDE_STOCK_MODAL: 'productinfo/HIDE_STOCK_MODAL',
  UPDATE_STOCK: 'productinfo/UPDATE_STOCK',
  ADD_SHOW_IMAGES: 'productinfo/ADD_SHOW_IMAGES',
  HIDE_SHOW_IMAGES: 'productinfo/HIDE_SHOW_IMAGES',
  SAVE_PRODUCT_IMAGES: 'productinfo/SAVE_PRODUCT_IMAGES',
  LOAD_PRODUCT_IMAGES: 'productinfo/LOAD_PRODUCT_IMAGES',
  UPDATE_IMAGE_STATUS: 'productinfo/UPDATE_IMAGE_STATUS',
  SHOW_DELETE_IMAGE: 'productinfo/SHOW_DELETE_IMAGE',
  HIDE_DELETE_IMAGE: 'productinfo/HIDE_DELETE_IMAGE',
  DELETEiMAGE: 'productinfo/DELETEiMAGE',
}

export default actions

import actions from './actions'

const initialState = {
  productlistdata: null,
  selectedData: null,
  search: null,
  searchproductnamelist: null,
  displayAddProductNameListDrawer: false,
  creating: false,
}

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddProductNameListDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddProductNameListDrawer: false }
    default:
      return state
  }
}

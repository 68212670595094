const actions = {
  SET_STATE: 'reports/SET_STATE',
  FILTER: 'reports/FILTER',
  LOAD_ORDERS_REPORT: 'reports/LOAD_ORDERS_REPORT',
  LOAD_VENDOR_INFO: 'reports/LOAD_VENDOR_INFO',
  LOAD_CATEGORY_REPORT: 'reports/LOAD_CATEGORY_REPORT',
  LOAD_PRODUCTS_REPORT: 'reports/LOAD_PRODUCTS_REPORT',
}

export default actions

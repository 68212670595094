const actions = {
  SET_STATE: 'orders/SET_STATE',
  SHOW_ORDER_DETAILS: 'orders/SHOW_ORDER_DETAILS',
  HIDE_ORDER_DETAILS: 'orders/HIDE_ORDER_DETAILS',
  GET_ORDER_DETAILS: 'orders/GET_ORDER_DETAILS',
  CHANGE_STATUS: 'orders/CHANGE_STATUS',
  LOAD_ALL_ORDERS: 'orders/LOAD_ALL_ORDERS',
  EXPORT_PDF_DOWNLOAD: 'orders/EXPORT_PDF_DOWNLOAD',
}

export default actions

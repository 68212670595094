import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import staff from './staff/sagas'
import vendor from './vendor/sagas'
import category from './category/sagas'
import products from './products/sagas'
import customer from './customer/sagas'
import orders from './orders/sagas'
import productinfo from './productinfo/sagas'
import reports from './reports/sagas'
import sliders from './sliders/sagas'
import invoice from './invoice/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    staff(),
    vendor(),
    category(),
    products(),
    customer(),
    orders(),
    productinfo(),
    reports(),
    sliders(),
    invoice(),
  ])
}

const actions = {
  SET_STATE: 'customer/SET_STATE',
  SHOW_CUSINFO: 'customer/SHOW_CUSINFO',
  HIDE_CUSINFO: 'customer/HIDE_CUSINFO',
  

  LOAD_ALL_CUSTOMER: 'customer/LOAD_ALL_CUSTOMER',
}

export default actions

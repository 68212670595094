import apiClient from 'services/axios'

const BASE_URL = 'WebProducts'

export async function loadproductlist(payload) {
  return apiClient
    .get(`${BASE_URL}/loadproductlistInfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function addproductname(payload) {
  return apiClient
    .post(`${BASE_URL}/addproductnameinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import staff from './staff/reducers'
import vendor from './vendor/reducers'
import category from './category/reducers'
import products from './products/reducers'
import customer from './customer/reducers'
import orders from './orders/reducers'
import productinfo from './productinfo/reducers'
import reports from './reports/reducers'
import sliders from './sliders/reducers'
import invoice from './invoice/reducers'
import RootAction from './actions'

export default history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    staff,
    vendor,
    category,
    products,
    customer,
    orders,
    productinfo,
    reports,
    sliders,
    invoice,
  })
  return (state, action) => {
    if (action.type === RootAction.CLEAR_STATE) {
      state = undefined
    }
    return appReducer(state, action)
  }
}

import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadproductlist, addproductname } from 'api/products'
import actions from './actions'

export function* LOAD_PRODUCTS_LIST() {
  const { response } = yield call(loadproductlist)
  if (response) {
    const { productlistinfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        productlistdata: productlistinfo,
      },
    })
  }
}

export function* ADD_PRODUCT_NAME({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(addproductname, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    const { productlistinfo } = response.data
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        productlistdata: productlistinfo,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_PRODUCTS_LIST, LOAD_PRODUCTS_LIST),
    takeLatest(actions.ADD_PRODUCT_NAME, ADD_PRODUCT_NAME),
  ])
}

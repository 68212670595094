import apiClient from 'services/axios'

const BASE_URL = 'WebCategory'

export async function loadcategory(payload) {
  return apiClient
    .get(`${BASE_URL}/getcategorylist`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getcategory(payload) {
  return apiClient
    .post(`${BASE_URL}/getcategoryname`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function addcategory(payload) {
  return apiClient
    .post(`${BASE_URL}/savecategoryinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatecategory(payload) {
  return apiClient
    .post(`${BASE_URL}/updatecategoryinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changecategoryimage(payload) {
  return apiClient
    .post(`${BASE_URL}/updatecategoryimageinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getcategorypdf() {
  return apiClient
    .get(`${BASE_URL}/exportcategorypdf`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

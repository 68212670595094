import actions from './actions'

const initialState = {
  ordersdata: null,
  searchorderplaced: null,
  showOrdersReportColumn: false,
  showcategoryReportColumn: false,
  showproductReportColumn: false,
  creating: false,
  startDate: null,
  endDate: null,
  orderType: null,
  reportFor: null,
}

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const actions = {
  SET_STATE: 'category/SET_STATE',
  SHOW_CREATE: 'category/SHOW_CREATE',
  HIDE_CREATE: 'category/HIDE_CREATE',
  SHOW_UPDATE: 'category/SHOW_UPDATE',
  HIDE_UPDATE: 'category/HIDE_UPDATE',
  UPDATE_CATEGORY: 'category/UPDATE_CATEGORY',
  EXPORTPDF: 'category/EXPORTPDF',

  LOAD_ALL_CATEGORY: 'category/LOAD_ALL_CATEGORY',
  ADD_CATEGORY: 'category/ADD_CATEGORY',
  GET_CATEGORY: 'category/GET_CATEGORY',
  CHANGE_CATEGORY_IMAGE: 'category/CHANGE_CATEGORY_IMAGE',
}

export default actions

import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadsliderslist, addsliderimage, updatesliderstatus, deleteimage } from 'api/sliders'
import actions from './actions'

export function* LOAD_SLIDERS_LIST() {
  const { response } = yield call(loadsliderslist)
  if (response) {
    const { getsliderslistinfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        slidersdata: getsliderslistinfo,
      },
    })
  }
}

export function* ADDSLIDERiMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(addsliderimage, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    const { getsliderslistinfo } = response.data
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        slidersdata: getsliderslistinfo,
      },
    })
  }
}

export function* UPDATE_SLIDER_STATUS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updatesliderstatus, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { getsliderslistinfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        slidersdata: getsliderslistinfo,
      },
    })
  }
}

export function* DELETEiMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      deleting: true,
    },
  })
  const { response } = yield call(deleteimage, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      deleting: false,
    },
  })
  if (response) {
    const { getsliderslistinfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        slidersdata: getsliderslistinfo,
        displayDeleteImagePopup: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_SLIDERS_LIST, LOAD_SLIDERS_LIST),
    takeLatest(actions.ADDSLIDERiMAGE, ADDSLIDERiMAGE),
    takeLatest(actions.UPDATE_SLIDER_STATUS, UPDATE_SLIDER_STATUS),
    takeLatest(actions.DELETEiMAGE, DELETEiMAGE),
  ])
}

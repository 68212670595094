import apiClient from 'services/axios'

const BASE_URL = 'WebVendor'

export async function loadvendor(payload) {
  return apiClient
    .get(`${BASE_URL}/getvendorinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function addvendor(payload) {
  return apiClient
    .post(`${BASE_URL}/addvendorinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatevendor(payload) {
  return apiClient
    .post(`${BASE_URL}/updatevendorinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getvendors(payload) {
  return apiClient
    .get(`${BASE_URL}/vendorlist`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
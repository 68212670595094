import actions from './actions'

const initialState = {
  productsinfodata: null,
  productsmasterdata: null,
  productcategorydata: null,
  searchproductsinfo: null,
  displayProductAddDrawer: false,
  displayProductUpdateDrawer: false,
  displayStockModal: false,
  categorylistdata: null,
  categorynamedata: null,
  userInfodata: null,
  vendorInfoData: null,
  data: null,
  roledata: null,
  selectedData: null,
  ImagesList: null,
  newlyAdded: null,
  search: null,
  Pid: null,
  company_data: null,
  displayAddCategoryDrawer: false,
  displayUpdateCategoryDrawer: false,
  displayAddImageDrawer: false,
  displayDeleteImagePopup: false,
  creating: false,
}

export default function productinfoReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayProductAddDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayProductAddDrawer: false }
    case actions.SHOW_STOCK_MODAL:
      return { ...state, selectedData: action.payload, displayStockModal: true }
    case actions.HIDE_STOCK_MODAL:
      return { ...state, selectedData: null, displayStockModal: false }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload, displayProductUpdateDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayProductUpdateDrawer: false }
    case actions.ADD_SHOW_IMAGES:
      return { ...state, selectedData: action.payload?.Pid, displayAddImageDrawer: true }
    case actions.HIDE_SHOW_IMAGES:
      return { ...state, selectedData: null, displayAddImageDrawer: false }
    case actions.SHOW_DELETE_IMAGE:
      return { ...state, selectedData: action.payload, displayDeleteImagePopup: true }
    case actions.HIDE_DELETE_IMAGE:
      return { ...state, selectedData: null, displayDeleteImagePopup: false }

    default:
      return state
  }
}

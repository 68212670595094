import apiClient from 'services/axios'

const BASE_URL = 'WebSliders'

export async function loadsliderslist(payload) {
  return apiClient
    .get(`${BASE_URL}/getsliderslist`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function addsliderimage(payload) {
  return apiClient
    .post(`${BASE_URL}/savesliderinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatesliderstatus(payload) {
  return apiClient
    .post(`${BASE_URL}/updatesliderinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteimage(payload) {
  return apiClient
    .post(`${BASE_URL}/deletesliderinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

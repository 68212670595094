import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadvendor, loadordersreport, loadcategoryreport, loadproductsreport } from 'api/reports'
import actions from './actions'

export function* LOAD_VENDOR_INFO() {
  const { response } = yield call(loadvendor)
  if (response) {
    const { vendorsInfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        vendorlistdata: vendorsInfo,
      },
    })
  }
}

export function* LOAD_ORDERS_REPORT({ payload }) {
  const { response } = yield call(loadordersreport, payload)
  if (response) {
    const { orderslistreport } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        ordersdata: orderslistreport,
        showOrdersReportColumn: true,
        showcategoryReportColumn: false,
        showproductReportColumn: false,
      },
    })
  }
}

export function* LOAD_CATEGORY_REPORT({ payload }) {
  const { response } = yield call(loadcategoryreport, payload)
  if (response) {
    const { categorylistreport } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        categorydata: categorylistreport,
        showcategoryReportColumn: true,
        showOrdersReportColumn: false,
        showproductReportColumn: false,
      },
    })
  }
}

export function* LOAD_PRODUCTS_REPORT({ payload }) {
  const { response } = yield call(loadproductsreport, payload)
  if (response) {
    const { productlistlistreport } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        productsdata: productlistlistreport,
        showproductReportColumn: true,
        showcategoryReportColumn: false,
        showOrdersReportColumn: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_VENDOR_INFO, LOAD_VENDOR_INFO),
    takeLatest(actions.LOAD_ORDERS_REPORT, LOAD_ORDERS_REPORT),
    takeLatest(actions.LOAD_CATEGORY_REPORT, LOAD_CATEGORY_REPORT),
    takeLatest(actions.LOAD_PRODUCTS_REPORT, LOAD_PRODUCTS_REPORT),
  ])
}

import actions from './actions'

const initialState = {
  customerlistdata: null,
  selectedData: null,
  search: null,
  searchproductnamelist: null,
  displayCustomerInfoDrawer: false,
  creating: false,
}

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CUSINFO:
      return { ...state, selectedData: action.payload.customer, displayCustomerInfoDrawer: true }
    case actions.HIDE_CUSINFO:
      return { ...state, selectedData: null, displayCustomerInfoDrawer: false }
    default:
      return state
  }
}

import actions from './actions'

const initialState = {
  slidersdata: null,
  data: null,
  selectedData: null,
  search: null,
  creating: false,
  displayImagePopup: false,
  displayImageuploadDrawer: false,
  displayDeleteImagePopup: false,
}

export default function slidersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_POPUP:
      return { ...state, displayImagePopup: true, selectedData: action.payload }
    case actions.HIDE_POPUP:
      return { ...state, displayImagePopup: false, selectedData: null }
    case actions.SHOW_CREATE:
      return { ...state, displayImageuploadDrawer: true, selectedData: action.payload }
    case actions.HIDE_CREATE:
      return { ...state, displayImageuploadDrawer: false, selectedData: null }
    case actions.SHOW_DELETE_IMAGE:
      return { ...state, displayDeleteImagePopup: true, selectedData: action.payload }
    case actions.HIDE_DELETE_CREATE:
      return { ...state, displayDeleteImagePopup: false, selectedData: null }
    default:
      return state
  }
}

import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadorders, getorderdetails, changestatus, pdfdownload } from 'api/orders'
import actions from './actions'

export function* LOAD_ALL_ORDERS() {
  const { response } = yield call(loadorders)
  if (response) {
    const { orderslist } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        orderlistdata: orderslist,
        displayOrderDetailDrawer: false,
      },
    })
  }
}

export function* GET_ORDER_DETAILS({ payload }) {
  const { response } = yield call(getorderdetails, payload)
  if (response) {
    const { orderdetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        orderdetailinfo: orderdetail,
      },
    })
  }
}

export function* CHANGE_STATUS({ payload }) {
  const { response } = yield call(changestatus, payload)
  if (response) {
    const { orderslist } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        orderlistdata: orderslist,
        displayOrderDetailDrawer: false,
      },
    })
  }
}

export function* EXPORT_PDF_DOWNLOAD({ payload }) {
  const { response } = yield call(pdfdownload, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        orderdownload: response?.data?.orderdownload,
        displayOrderDetailDrawer: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL_ORDERS, LOAD_ALL_ORDERS),
    takeLatest(actions.CHANGE_STATUS, CHANGE_STATUS),
    takeLatest(actions.GET_ORDER_DETAILS, GET_ORDER_DETAILS),
    takeLatest(actions.EXPORT_PDF_DOWNLOAD, EXPORT_PDF_DOWNLOAD),
  ])
}

import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'title.helmet.ProductList': 'Product List',
  'title.helmet.slidersList': 'Sliders List',
  'title.helmet.Leads': 'Manage Leads',
  'title.helmet.Category': 'Manage Category',
  'title.helmet.Customer': 'Manage Customers',
  'title.helmet.productsList': 'Product List',
  'title.summary.reports': 'Reports',
  'text.ProductList': 'Product List',
  'text.CustomerList': 'Customer List',
  'text.leadList': 'Lead List',
  'title.helmet.User': 'Manage Users',
  'text.userList': 'User List',
  'text.slidersList': 'Sliders List',
  'text.updateLeads': 'Update Leads',
  'text.ChangePassword': 'Change Password',
  'text.changePassword': 'Change Password',
  'text.removeLead': 'Remove Lead',
  'text.removeLead.description': 'Are you sure you want to delete this lead?.',
  'text.addLead': 'Add Lead',
  'action.createLeads': 'Create Leads',
  'text.viewleads.sno': 'Sno.',
  'text.viewleads.business_name': 'Business Name',
  'text.viewleads.emailid': 'Email Id',
  'text.viewleads.mobile': 'Mobile',
  'text.viewleads.type': 'Type',
  'text.viewleads.lead_owner': 'Lead Owner',
  'text.viewleads.status': 'Status',
  'text.viewleads.contact_name': 'Contact Name',
  'text.custommessage.leadsData': 'No Leads to show',
  'text.CategoryList': 'Category List',
  'form.user.placeholder.searchLeads': 'Search by Business Name or Contact Name or Email',
  'form.user.placeholder.searchcategory': 'Search by Category Name',
  'text.is_active': 'Status',
  'text.CategoryName': 'Category Name',
  'text.productsList': 'Product Name List',
  'text.productname': 'Product Name',
  'form.label.productname': 'Product Name',
  'text.categoryname': 'Category Name',
  'text.custommessage.productNameListData': 'No Product Name List Records Available',
  'form.user.placeholder.searchProductNameList': 'Search By Product Name',
  'action.LinkwithCategory': 'Link with Category',
  'action.addProductName': 'Add Product Name',
  'text.addProductname': 'Add Product Name',
  'form.placeholder.productname': 'Enter the Product Name',
  'text.CustomerUsername': 'Customer Username',
  'text.CustomerPassword': 'Customer Password',
  'text.customercreateddate': 'Created Date',
  'text.orders.search': 'Enter the OrderID or Order Status  or Customer Name or Mobile',
  'text.custommessage.orders': 'No Orders Found',
  'form.placeholder.units': 'Units',
  'text.orderCancelled': 'Order Cancelled',
  'text.failed': 'Order Failed',
  'text.paymentStatus': 'Payment Status',
  'text.OrderFailed': 'Order Failed',

  'text.custommessage.SlidersListData': 'No Slider Image Found',
  'text.imageName': 'Image name',
  'action.View': 'View Image',
  'action.delete': 'Delete Image',
  'text.addSliderImage': 'Add New Slider Image',
  'text.categoryImages': 'Category Image',
  'form.select.All': 'All',
  'text.AddImage': 'Upload Image',
  'action.upload': 'Upload',
  'form.label.hsn_code': 'HSN Code',
  'form.placeholder.hsn_code': 'HSN Code',
  'text.AddProductImages': 'Add Images',
  'text.AddImages': 'Add Images',
  'text.ViewproductImages': 'View Images',
  'text.imagename': 'Image Name',
  'text.Is_Active': 'Status',
  'text.custommessage.ProductImages': 'No Record for Product Images',

  'form.label.from': 'Start Date',
  'form.label.to': 'End Date',
  'form.label.reportFor': 'Report For',
  'form.select.Order': 'Orders',
  'form.select.category': 'Category',
  'form.select.products': 'Products',

  'form.label.orderType': 'Order Type',
  'form.select.all': 'All',
  'form.select.OrderPlaced': 'Order Placed',
  'form.select.InTransit': 'In Transit',
  'form.select.outForDelivery': 'Order Delivery',
  'form.select.orderCompleted': 'Order Completed',

  'form.select.active': 'Active',
  'form.select.inactive': 'In Active',
  'action.search': 'Search',
  'form.select.selectFromList': 'Select From List',

  'form.label.oldpassword': 'Old Password',
  'form.placeholder.oldpassword': 'Enter the old password',
  'form.placeholder.newpassword': 'Enter the new password',
  'form.placeholder.repeatnewpassword': 'Enter the new password again',
  'form.label.newpassword': 'New Password',

  'text.changeLeadOwner.description': 'Change Lead Owner',
  'text.changeLeadOwner': 'Change Lead Owner',
  'form.label.lead_owner': 'Lead Owner',
  'form.placeholder.changeLeadOwner': 'Select the Lead Owner',
  'text.AddLeadNotes': 'Add Notes for Lead',
  'text.clearfilters': 'Clear Filter',
  'action.refinstock': 'Refill Stock',
  'action.updateStock': 'Update Stock',
  'form.label.currentstock': 'Current Stock',
  'form.placeholder.currentstock': 'Enter the Current Stock',
  'form.label.stockAction': 'Stock Action',
  'text.add': 'Add',
  'text.remove': 'Remove',
  'form.label.mrpPrice': 'MRP',
  'form.placeholder.mrpPrice': 'MRP',
  'form.mrpPrice.required': 'MRP Required',
  'text.price': 'Price',
  'text.UpdateProducts': 'Update Products',

  'form.label.business_name': 'Business Name',
  'form.placeholder.business_name': 'Business Name',
  'form.label.contact_name': 'Contact Name',
  'form.placeholder.contact_name': 'Contact Name',
  'form.label.emailid': 'Email Id',
  'form.placeholder.emailid': 'Email Id',
  'form.label.mobile': 'Mobile Number',
  'form.label.phone': 'Phone Number',
  'form.placeholder.phone': '0422 264925',
  'form.label.website_url': 'Website URL',
  'form.placeholder.website_url': 'https://www.example.com',
  'form.label.type': 'Type',
  'form.placeholder.type': 'Select the Type from list',
  'form.label.gst_number': 'GST Number',
  'form.placeholder.gst_number': '22AABCU9603R1ZX',
  'form.label.company_since': 'Company Since',
  'form.placeholder.company_since': '1947',
  'form.label.leadstatus': 'Lead Status',
  'form.label.addressline1': 'Address Line 1',
  'form.placeholder.addressline1': 'Address Line 1',
  'form.label.addressline2': 'Address Line 2',
  'form.placeholder.addressline2': 'Address Line 2',
  'form.label.addressline3': 'Address Line 3',
  'form.placeholder.addressline3': 'Address Line 3',
  'form.label.city': 'City',
  'form.placeholder.city': 'City',
  'form.label.landmark': 'Land Mark',
  'form.placeholder.landmark': 'Land Mark',
  'form.label.state': 'State',
  'form.placeholder.state': 'State',
  'form.label.country': 'Country',
  'form.placeholder.country': 'Country',
  'form.label.pincode': 'Pincode',
  'form.placeholder.pincode': 'Pincode',

  'action.active': 'Active',
  'action.inactive': 'Inactive',
  'text.action': 'Action',
  'action.createUser': 'Create User',

  'form.label.description': 'Description',
  'form.placeholder.description': 'Enter the Description',
  'text.igst': 'IGST',
  'text.cgst': 'CGST',
  'form.label.tax': 'Tax Type',
  'form.label.quanity': 'Quantity',
  'form.label.units': 'Units',
  'form.label.CGSTtaxValue': 'CGST',
  'form.label.SGSTtaxValue': 'SGST',
  'form.label.IGSTtaxValue': 'IGST',
  'form.CGSTtaxValue.required': 'CGST Tax Required',
  'form.SGSTtaxValue.required': 'SGST Tax Required',
  'form.IGSTtaxValue.required': 'IGST Tax Required',
  'form.placeholder.CGSTtaxValue': 'Enter CGST tax Percentage',
  'form.placeholder.SGSTtaxValue': 'Enter SGST tax Percentage',
  'form.placeholder.IGSTtaxValue': 'Enter IGST tax Percentage',
  'form.label.itemprice': 'Item Price',
  'form.placeholder.itemPrice': 'Enter the Item Price',
  'form.itemPrice.required': 'Item Price is Required',

  'text.viewVendorInfo': 'View Vendor',
  'action.addproducts': 'Add Products',
  'text.addproducts': 'Add Products',
  'text.AddProducts': 'Add Products',
  'text.productlogo': 'Product Logo',
  'text.quantity': 'Quantity (Units)',
  'text.units': 'Units',
  'text.DeliveryCost': 'Delivery Fees',
  'form.user.placeholder.searchbyproductinfo': 'Search by product name',

  'action.exportPdf': 'Export PDF',
  'action.exportExcelOrderDetail': 'Export Order Details as Excel',
  'action.exportExcelOrders': 'Export Order Info as Excel',
  'text.subTotal': 'Sub Total',
  'text.deliveryFees': 'Delivery Fees',
  'text.tax': 'Tax',
  'text.total': 'Total Billable Amount',
  'text.placed': 'Order Placed Date',
  'text.orderPlaced': 'Order Placed',
  'text.orderTransit': 'In Transit',
  'action.exportExcel': 'Export Excel',
  'text.orderDelivery': 'Out for Delivery',
  'text.discount': 'Discount',
  'text.orderCompleted': 'Order Completed',
  'text.vendorName': 'Vendor Name',
  'text.vendorID': 'Vendor ID',
  'text.sno': 'S.No',
  'text.customerMobile': 'Customer Mobile',
  'text.OrderID': 'Order ID',
  'text.OrderTotal': 'Order Total (INR)',
  'text.orderdescription': 'Order Status',
  'text.orderstatus': 'Order Status Code',
  'text.createddate': 'Order Created Date',
  'text.orderPlaced.search': 'Search by Order ID or Customer Name and Mobile',
  'text.custommessage.orderPlaced': 'No Records for Order Placed Status',
  'text.custommessage.orderTransit': 'No Records for Order Transit Status',
  'text.custommessage.orderDelivery': 'No Records for Order Delivery Status',
  'text.custommessage.orderCompleted': 'No Records for Order Completed Status',

  'text.serialNo': 'S.No',
  'text.name': 'Name',
  'text.mobile': 'Mobile',
  'text.email': 'Email',
  'text.role': 'Role',
  'text.status': 'Status',
  'text.addUser': 'Add User',
  'text.updateUser': 'Update User',
  'form.label.user_status': 'Status',
  'form.label.phoneoptional': 'Mobile Number (Optional)',
  'form.user.placeholder.searchstaff': 'Search by Name or Email',

  'form.label.contactName': 'Name',
  'form.placeholder.contactName': 'Enter the name',
  'form.placeholder.mobile': '(123) 456-7890',
  'text.custommessage.staffData': 'No Staff Records Available',
  'text.custommessage.CategoryData': 'No Category Records Available',
  'text.custommessage.customerListData': 'No Customer Records Available',
  'text.removeUser.description': 'Are you sure you want to delete this user?.',

  'text.CustomerDetails': 'Customer Information',
  'form.label.CustomerName': 'Customer Name',
  'form.placeholder.CustomerName': 'Customer Name',
  'form.label.CustomerEmail': 'Email',
  'form.placeholder.CustomerEmail': 'Email',
  'form.label.CustomerMobileNo': 'Mobile No',
  'form.placeholder.CustomerMobileNo': 'Mobile No',
  'form.label.Customerhouseno': 'House No',
  'form.placeholder.Customerhouseno': 'House No',
  'form.label.Customeraddress': 'Address',
  'form.placeholder.Customeraddress': 'Address',
  'form.label.Customercity': 'City',
  'form.placeholder.Customercity': 'City',
  'form.label.Customerpincode': 'Pincode',
  'form.placeholder.Customerpincode': 'Pincode',
  'form.label.Customerdistrict': 'District',
  'form.placeholder.Customerdistrict': 'District',
  'form.label.Customerstate': 'State',
  'form.placeholder.Customerstate': 'State',
  'form.label.Customercountry': 'Country',
  'form.placeholder.Customercountry': 'Country',
  'form.label.Customerlandmark': 'Land Mark',
  'form.placeholder.Customerlandmark': 'Land Mark',

  'text.customerName': ' CustomerName',
  'text.customerEmail': 'Email',
  'text.CustomerMobileNo': 'Mobile No',
  'text.customerCity': 'City',
  'text.customerPincode': 'Postal/Zip Code',
  'action.viewInfo': 'View Info',
  'action.viewDevice': 'View Devices',
  'form.user.placeholder.searchbyCustomer': 'Search by Name or Email or mobile',

  'text.space': '{count, plural, =1{1} other{#}}',
  'text.default': 'Default',
  'text.makedefault': 'Make this card as default',
  'action.addnewcard': 'Add New Card',
  'action.add': 'Add',
  'action.addcard': 'Add Card',
  'action.save': 'Save',
  'action.yes': 'Yes',
  'action.no': 'No',
  'action.addCustomImage': 'Add Custom Image',
  'text.logo': 'Logo',
  'text.showaddcard': 'Add New Card',
  'text.storedesign': 'Store Design',
  'text.viewStore': 'View Store',
  'form.label.mobileappcoverimage': 'Mobile App Cover Image',
  'form.label.themeColor': 'Theme Color',
  'action.changeColor': 'Change Color',
  'desc.coverImage': 'Cover Image',
  'desc.backgroundImage': 'Cover Image',
  'action.change': 'Change',
  'text.storeino.tab.orders': 'Orders',
  'text.deviceinfo.Settings': 'Settings',
  'text.tabepaysettings': 'Table Pay Settings',
  'text.storeino.tab.tablepaysettings': 'Table Pay Settings',
  'text.tablepaysettings': 'Table Pay Settings',
  'form.label.webstorebackgroundimage': 'Web Store background Image',
  'note.logoSize':
    'Image dimension recommended in JPG or PNG format with the size less than 200kb.',
  'action.resend': 'Resend',
  'action.edit': 'Edit',
  'action.remove': 'Remove',
  'action.retry': 'Retry',
  'action.update': 'Update',
  'action.void': 'Void',
  'text.resendinvoice': 'Resend Invoice',
  'text.showresendinvoice': 'Resend Invoice',
  'text.voidinvoice': 'Void Invoice',
  'text.showvoidinvoice': 'Void Invoice',
  'text.resendinvoice.description': 'Do you want to resend this invoice?',
  'text.voidinvoice.description': 'Do you want to void this invoice?',
  'text.retrypayment': 'Retry Payment',
  'text.showretrypayment': 'Retry Payment',
  'text.showupdatepayment': 'Update Payment',
  'text.retrypayment.description': 'Do you want to retry the payment?',
  'text.updatepayment.description': 'Do you want to update the payment?',

  'title.helmet.store': 'Manage Stores',
  'text.manage.stores': 'Manage Stores',
  'text.store.tab.active': 'Active',
  'text.store.tab.inactive': 'Inactive',
  'text.store.tab.demo': 'Demo',

  'text.active.store.sno': 'S.No',
  'text.active.store.store_name': 'Store Name',
  'text.active.store.phone_number': 'Phone Number',
  'text.active.store.email': 'Email',
  'text.active.store.reseller': 'Reseller',
  'text.active.store.agent': 'Agent',
  'text.active.store.billing_type': 'Payment Method',
  'action.active.store.billing_type': 'Change Payment Method',
  'text.active.store.pos_software': 'Restaurant Software',
  'text.active.store.search': 'Search by store name or email or reseller',

  'text.inactive.store.sno': 'S.No',
  'text.inactive.store.store_name': 'Store Name',
  'text.inactive.store.phone_number': 'Phone Number',
  'text.inactive.store.email': 'Email',
  'text.inactive.store.reseller': 'Reseller',
  'text.inactive.store.agent': 'Agent',
  'text.inactive.store.billing_type': 'Payment Method',
  'action.inactive.store.billing_type': 'Change',
  'text.inactive.store.search': 'Search by store name or email or reseller',

  'text.demo.store.sno': 'S.No',
  'text.demo.store.store_name': 'Store Name',
  'text.demo.store.phone_number': 'Phone Number',
  'text.demo.store.email': 'Email',
  'text.demo.store.reseller': 'Reseller',
  'text.demo.store.agent': 'Agent',
  'text.demo.store.billing_type': 'Payment Method',
  'action.demo.store.billing_type': 'Change',
  'text.demo.store.search': 'Search by store name or email or reseller',

  'title.helmet.storeinfo': 'Store Info',
  'text.storeinfo': 'Store Info',
  'text.storeino.tab.storeinfo': 'Store Info',
  'text.storeino.tab.subscriptioninfo': 'Subscription',
  'text.storeino.tab.deviceinfo': 'Device Info',
  'text.storeino.tab.paymenthistory': 'Payment History',
  'text.storeino.tab.notes': 'Notes',
  'text.storeino.tab.openorder': 'Open Order',
  'text.storeino.tab.closeorder': 'Closed Order',
  'text.storeino.tab.coseorder': 'Closed Order',

  'text.storeino.tab.windowsclient': 'Windows Client',
  'text.storeino.tab.paymentterminal': 'Payment Terminal',

  'title.helmet.deviceinfo': 'Device Info',
  'text.deviceinfo.sno': 'S.No',
  'text.deviceinfo.devicename': 'Device Name',
  'text.deviceinfo.ipaddress': 'IP Address',
  'text.deviceinfo.macaddress': 'MAC Address',
  'text.deviceinfo.lastactivity': 'Last Activity',
  'text.deviceinfo.networkstatus': 'Network Status',
  'text.deviceinfo.logs': 'Logs',
  'text.deviceinfo.devicetype': 'Device Type',
  'text.deviceinfo.viewlogs': 'View Logs',
  'text.devicelogs': 'Device Logs',
  'text.deviceinfo.servicestatus': 'Service Status',
  'text.deviceinfo.servicestatusrunning': 'Running',
  'text.deviceinfo.servicestatusstopped': 'Stopped',
  'text.deviceinfo.version': 'Version',
  'form.paymentterminal.placeholder.search': 'Search by device name',
  'form.windowsclient.placeholder.search': 'Search by device name',
  'text.errorlogsmessage': 'No Error Logs to show',

  // Open Order Table Header

  'text.openorder.sno': 'S.No',
  'text.openorder.orderno': 'Order Number',
  'text.openorder.ordertype': 'Order Type',
  'text.openorder.tableno': 'Table Number',
  'text.openorder.seatno': 'Seat Number',
  'text.openorder.servername': 'Server Name',
  'text.openorder.ordertotal': 'Order Total',
  'text.openorder.paymentstatus': 'Payment Status',
  'text.openorder.order_status': 'Order Status',
  'text.openorder.orderinfo': 'Order Info',
  'text.openorder.orderdetail': 'Order Detail',
  'text.openorder.search': 'Search by order number or user',
  'text.orderamountdue': 'Amount Due',
  'text.orderno': 'Order#',
  'text.orderamountpaidwithtip': 'Amount Paid (Incl.tip)',
  'text.orderamountpaid': 'Amount Paid',
  'text.openorder.user': 'User',

  'text.ordertableno': 'Table Number',
  'text.orderseatno': 'Seat Number',
  'text.orderpaymentstatus': 'Payment Status',
  'text.ordertype': 'Order Type',
  'text.ordertip': 'Tip',
  'text.orderusername': 'User',
  'text.ordercreatedon': 'Created',
  'text.orderinfo': 'Order Summary',
  'text.orderpaymentmethod': 'Payment Method',
  'text.ordertransactiontype': 'Transaction Type',
  'text.orderauthnumber': 'Auth Number',
  'text.ordertransactiondate': 'Transaction Date/Time',

  'text.closeorder.sno': 'S.No',
  'text.closeorder.orderno': 'Order Number',
  'text.closeorder.ordertype': 'Order Type',
  'text.closeorder.tableno': 'Table Number',
  'text.closeorder.seatno': 'Seat Number',
  'text.closeorder.servername': 'Server Name',
  'text.closeorder.ordertotal': 'Order Total',
  'text.closeorder.orderdetail': 'Order Detail',
  'text.closeorder.paymentstatus': 'Payment Status',
  'text.closeorder.search': 'Search by order number or user',
  'text.closeorder.user': 'User',

  'form.storeinfo.label.storename': 'Store Name',

  'text.storedetail.storedetailinfo': 'Store Details',
  'text.storedetail.contactdetails': 'Contact Details',
  'text.storedetail.businessdetails': 'Business Details',
  'action.storedetail.update': 'Update',

  'form.storedetail.label.storename': 'Store Name',
  'form.storedetail.label.storeaddress': 'Address',
  'form.storedetail.label.storecity': 'City',
  'form.storedetail.label.storeprovince': 'Province',
  'form.storedetail.label.storepostalcode': 'Zip/Postal Code',
  'form.storedetail.label.storecountry': 'Country',
  'form.storedetail.label.storebusinessid': 'Business ID',
  'form.storedetail.label.storephone': 'Phone Number',
  'form.storedetail.label.storealternatephone': 'Alt. Phone Number',
  'form.storedetail.label.storeemail': 'Email',
  'form.storedetail.label.storeowner': 'Business Owner',
  'form.storedetail.label.storetype': 'Restaurant Type',
  'form.storedetail.label.storelocations': 'No. of Locations',
  'form.storedetail.label.storeintersection': 'Intersection/Plaza',
  'form.storedetail.label.storecategory': 'Category',
  'form.storedetail.label.storepricerange': 'Price Range',
  'form.storedetail.label.firstName': 'First Name',
  'form.storedetail.label.lastName': 'Last Name',
  'form.storedetail.label.email': 'Email',
  'form.storedetail.label.phone_number': 'Phone Number',
  'form.storedetail.label.alternate_phone_number': 'Alt. Phone Number',
  'form.storedetail.label.businessid': 'Business ID',
  'form.storedetail.label.locationid': 'Location ID',
  'form.storedetail.label.nooflocations': 'No of Locations',
  'form.storedetail.label.category': 'Category',
  'form.storedetail.label.intersectionplaza': 'Intersection Plaza',
  'form.storedetail.label.pricerange': 'Price Range',
  'form.label.businesstype': 'Business Type',
  'form.label.username': 'Username',

  'form.storedetail.placeholder.storename': 'Enter the store name',
  'form.storedetail.placeholder.storetype': 'Enter the Restaurant Type',
  'form.storedetail.placeholder.storeaddress': 'Enter the address',
  'form.storedetail.placeholder.storecity': 'Enter the City',
  'form.storedetail.placeholder.storeprovince': 'Enter the Province',
  'form.storedetail.placeholder.storepostalcode': 'Enter the Zip/Postal code',
  'form.storedetail.placeholder.storecountry': 'Enter the country',
  'form.storedetail.placeholder.firstName': 'Enter the first name',
  'form.storedetail.placeholder.lastName': 'Enter the last name',
  'form.storedetail.placeholder.email': 'Enter the email',
  'form.storedetail.placeholder.phone_number': '(123) 456-7890',
  'form.storedetail.placeholder.alternate_phone_number': '(123) 123-1234',
  'form.storedetail.placeholder.businessid': 'Enter the business ID',
  'form.storedetail.placeholder.locationid': 'Enter the location ID',
  'form.storedetail.placeholder.nooflocations': 'Locations',
  'form.storedetail.placeholder.category': 'Enter the category',
  'form.storedetail.placeholder.intersectionplaza': 'Enter the Intersection Plaza',

  'title.helmet.user': 'Manage Users',
  'text.user': 'Manage Users',
  'text.userinfo.sno': 'S.No',
  'text.userinfo.name': 'Name',
  'text.userinfo.email': 'Email',
  'text.userinfo.phone_number': 'Phone Number',
  'action.userinfo.edit': 'Edit',
  'text.userinfo.status': 'Status',
  'text.userinfo.action': 'Action',
  'text.userinfo.active': 'Active',
  'text.userinfo.inactive': 'Inactive',
  'text.user.placeholder.search': 'Search..',

  'title.helmet.invoice': 'Invoice',
  'text.viewinvoices': 'View Invoice',
  'text.invoices': 'Manage Invoices',
  'text.paidinvoicelabel': 'PAID',
  'text.notpaidinvoicelabel': 'NOT PAID',
  'text.invoices.placeholder.search': 'Search..',

  'text.invoice.creditcardbilling': 'Credit Card Billing',
  'text.invoice.creditcard.paid': 'Paid Invoices',
  'text.invoice.creditcard.unpaid': 'Unpaid Invoices',
  'text.paymenthistory.paid.sno': 'S.No',
  'text.paymenthistory.paid.invoice_no': 'Invoice Number',
  'text.paymenthistory.paid.storename': 'Store Name',
  'text.paymenthistory.paid.invoice_amount': 'Invoice Amount',
  'text.paymenthistory.paid.invoice_date': 'Invoice Date',
  'text.paymenthistory.paid.payment_status': 'Payment Status',
  'text.paymenthistory.paid.payment_method': 'Payment Method',
  'text.paymenthistory.paid.payment_date': 'Payment Date',
  'text.paymenthistory.paid..placeholder.search': 'Search..',

  'text.creditcard.paid.sno': 'S.No',
  'text.creditcard.paid.invoice_no': 'Invoice Number',
  'text.creditcard.paid.storename': 'Store Name',
  'text.creditcard.paid.invoice_amount': 'Invoice Amount',
  'text.creditcard.paid.invoice_date': 'Invoice Date',
  'text.creditcard.paid.payment_status': 'Payment Status',
  'text.creditcard.paid.payment_method': 'Payment Method',
  'text.creditcard.paid.payment_date': 'Payment Date',
  'text.creditcard.paid..placeholder.search': 'Search by invoice number or invoice date',

  'text.invoice.bamboradebit.paid': 'Paid Invoices',
  'text.invoice.bamboradebit.unpaid': 'Unpaid Invoices',
  'text.bamboradebit.paid.sno': 'S.No',
  'text.bamboradebit.paid.invoice_no': 'Invoice Number',
  'text.bamboradebit.paid.storename': 'Store Name',
  'text.bamboradebit.paid.invoice_amount': 'Invoice Amount',
  'text.bamboradebit.paid.invoice_date': 'Invoice Date',
  'text.bamboradebit.paid.payment_status': 'Payment Status',
  'text.bamboradebit.paid.payment_method': 'Payment Method',
  'text.bamboradebit.paid.payment_date': 'Payment Date',
  'text.bamboradebit.paid..placeholder.search': 'Search..',

  'text.invoice.cloverbilling': 'Clover Billing',
  'text.invoice.cloverecommerce.paid': 'Paid Invoices',
  'text.invoice.cloverecommerce.unpaid': 'Unpaid Invoices',
  'text.cloverecommerce.paid.sno': 'S.No',
  'text.cloverecommerce.paid.invoice_no': 'Invoice Number',
  'text.cloverecommerce.paid.storename': 'Store Name',
  'text.cloverecommerce.paid.invoice_amount': 'Invoice Amount',
  'text.cloverecommerce.paid.invoice_date': 'Invoice Date',
  'text.cloverecommerce.paid.payment_status': 'Payment Status',
  'text.cloverecommerce.paid.payment_method': 'Payment Method',
  'text.cloverecommerce.paid.payment_date': 'Payment Date',
  'text.cloverecommerce.paid..placeholder.search': 'Search..',

  'text.cloverecommerce.unpaid.sno': 'S.No',
  'text.cloverecommerce.unpaid.invoice_no': 'Invoice Number',
  'text.cloverecommerce.unpaid.storename': 'Store Name',
  'text.cloverecommerce.unpaid.invoice_amount': 'Invoice Amount',
  'text.cloverecommerce.unpaid.invoice_date': 'Invoice Date',
  'text.cloverecommerce.unpaid.payment_status': 'Payment Status',
  'text.cloverecommerce.unpaid.payment_method': 'Payment Method',
  'text.cloverecommerce.unpaid.payment_date': 'Payment Date',
  'text.cloverecommerce.unpaid..placeholder.search': 'Search..',

  'text.invoice.poyntbilling': 'Poynt Billing',
  'text.invoice.bamborabillingbilling': 'Bambora Billing',

  'text.receiptsettings': 'Receipt Settings',
  'text.preferences': 'Preferences',
  'form.label.printreceipt': 'Print receipt after the order is completed?',
  'form.label.printmerchantcopy': 'Print merchant copy after the order is completed?',
  'form.label.footerline': 'Footer Line Text',
  'form.error.footerline.required': 'Please enter the text',
  'form.placeholder.footerline': 'Please enter the footer line text',
  'form.label.idletimeout': 'Idle Timeout (In seconds)',
  'form.error.idletimeout.required': 'Idle timeout required',
  'form.placeholder.idletimeout': '0 means disabled',
  'form.label.tipamount': 'Tip Options',
  'form.label.tip': 'Tip',
  'form.label.tipoptions': 'Choose exactly 3 tip options',

  'form.placeholder.message': 'Type your notes here',

  'text.active': 'Active',
  'text.inactive': 'Inactive',
  'text.demo': 'Demo',
  'text.viewactivitylog': 'Activity Log',
  'text.removeuser.description': 'Are you sure you want to remove the user?',

  'form.placeholder.search': 'Search..',

  'text.stafflist': 'Manage Users',
  'text.totalCount': '{count, plural, =1{1 record} other{# records}}',
  'text.editstaff': 'Update User',

  'text.viewstaff.sno': 'S.No.',
  'text.viewstaff.name': 'Name',
  'text.viewstaff.email': 'Email',
  'text.viewstaff.mobile': 'Phone Number',
  'text.viewstaff.role': 'Role',
  'text.viewstaff.status': 'Status',
  'text.viewstaff.action': 'Action',
  'text.removeUser': 'Remove {name}',
  'text.createstaff': 'Create User',
  'action.createstaff': 'Create User',
  'form.label.role': 'Role',
  'form.label.name': 'Contact Name',
  'form.label.intersection': 'Intersection',

  'form.label.reseller': 'Reseller',
  'form.placeholder.role': 'Select the role',
  'form.placeholder.name': 'Enter the name',
  'form.placeholder.intersection': 'Enter the Intersection',
  'form.placeholder.password': 'Enter the password',
  'form.placeholder.reseller': 'Select the Reseller',
  'action.create': 'Create',
  'form.label.timezone': 'Timezone',
  'form.error.timezone.required': 'Please select the timezone',
  'form.placeholder.timezone': 'Select the timezone',

  'text.changepayment': 'Change Payment Method',
  'form.label.changepayment': 'Payment Method',
  'form.placeholder.changepayment': 'Select payment method',
  'text.billingtype': 'Payment Method',
  'form.label.cardNumber': 'Card Number',
  'form.label.creditCard': 'Credit Card',
  'form.label.cvv': 'CVV',
  'form.label.zipPostalCode': 'Zip/Postal Code',
  'form.placeholder.zipcode': 'Zip/Postal Code',
  'form.placeholder.cardName': 'Name on card',
  'form.label.cardname': 'Name',
  'form.error.required': 'Required',
  'text.billingtype.sno': 'S.No.',
  'text.billingtype.card': 'Payment Method',
  'text.billingtype.action': 'Action',
  'form.label.vendorName': 'Vendor Name',
  'form.placeholder.vendorname': 'Enter Business name of Vendor',
  'form.placeholder.vendorName': 'Vendor name of the product',
  'form.label.deliveryCost': 'Delivery Cost',
  'form.placeholder.deliveryCost': 'Delivery Cost',
  'form.placeholder.vendor': 'Select the Vendor from list',
  'title.helmet.Vendor': 'Manage Vendors',
  'text.VendorList': 'Vendor List',
  'action.addCategory': 'Add Category',
  'form.label.category': 'Category Name',
  'action.AddVendor': 'Add Vendor',
  'text.addVendor': 'Add Vendor',
  'form.label.vendorStatus': 'Status',
  'text.updateVendor': 'Update Vendor',
  'text.categoryImage': 'Category Image( Click on image to upload other image)',
  'text.addCategory': 'Add Category',
  'text.updateCategory': 'Update Category',
  'form.label.categoryname': 'Category Name',
  'form.placeholder.categoryname': 'Enter the Category Name',
  'text.Name': 'Vendor Name',
  'form.label.vendorregno': 'Vendor Registration No',
  'form.label.gstnumber': 'GST No',
  'form.placeholder.gstnumber': 'Enter the GST No',
  'form.placeholder.vendorregno': 'Enter Vendor Registration No',
  'text.contactName': 'Contact Name',
  'text.city': 'City',
  'text.address': 'Address',
  'form.label.address': 'Address',
  'form.label.postal_code': 'Postal Code',
  'form.placeholder.postal_code': 'Enter the Postal Code',
  'form.placeholder.address': 'Enter the Address',
  'form.user.placeholder.searchVendor': 'Search by Vendor Name or Contact Name or city',
  'text.custommessage.VendorData': 'No Vendor Records Found',

  'text.createInvoice': 'Create Bill',
  'action.createBill': 'Create Bill',
  'form.placeholder.search.bills': 'Search Bill',
  'text.custommessage.BillList': 'No data found for Bill',

  'text.Action': 'Action',
  'text.createdby': 'Created By',
  'text.invoiceDate': 'Bill Date',
  'text.invoiceAmount': 'Bill Amount',
  'text.invoice_number': 'Bill No',
  'text.businessName': 'Customer Name',
  'form.placeholder.gst': 'GST',
  'form.label.gst': 'GST',
  'form.label.discount': 'Discount',
  'form.placeholder.BusinessName': 'Customer Name',
  'form.label.BusinessName': 'Customer Name',
  'form.label.Type': 'Type',
  'form.placeholder.Type': 'Type',
  'form.label.customerName': 'Customer Name',
  'form.placeholder.customerName': 'Customer Name',
  'form.placeholder.category': 'Catageory Name',

  'form.placeholder.products': 'Product Name',
  'form.label.products': 'Product Name',




  'topBar.profileMenu.changePassword': 'Change Password',
  'topBar.profileMenu.logout': 'Logout',
  'text.copyright': 'Copyright © 2023 Biz Profit Pulse. All rights reserved.',
  'text.signIn.title': 'Sign in to your account',
  'text.dontHaveAccount': "Don't have an account?",
  'action.signUp': 'Sign Up',
  'action.next': 'Next',
  'action.gotosignin': 'Go to Sign in',
  'action.forgotPassword': 'Forgot password?',
  'action.signIn': 'Sign in',
  'form.label.password': 'Password',
  'form.error.password.max': 'Password must be up to 10 characters',
  'form.error.password.min': 'Password must be at least 8 characters',
  'form.error.password.required': 'Please input the password',
  'form.error.email.required': 'Please input the username or email address',
  'form.error.email.type': 'Invalid email address',
  'form.error.email.max': 'Email address must be up to 25 characters',
  'form.placeholder.email': 'Enter the email',
  'form.label.email': 'Email Id',
  'form.label.active': 'Active',
  'form.label.inactive': 'Inactive',
  'form.placeholder.company': 'Company Name',
  'form.label.company': 'Company Name',
  'form.error.empty.select': 'No matches found.',
  'form.placeholder.province': 'Enter the Province/State',
  'desc.logo': 'Logo Image',
  'title.helmet.orderinfo': 'Order Summary',
  'title.helmet.devicelogs': 'Device Info',
  'text.stacktrace': 'Stacktrace',
  'text.message': 'Message',
  'text.changepayment.description': ' ',
  'text.notes.button_message': 'Add Notes ',
  'form.storedetail.label.status': 'Account Status',
  'form.user.placeholder.search': 'Search by name or email or role',
  'form.label.agent_id': 'Agent ID',
  'text.resetpassword': 'Recover Password',
  'text.resetbutton': 'Reset',
  'title.recoverPassword': 'Recover Password',
  'action.recoverPassword': 'Recover my password',
  'title.resetPassword': 'Reset Password',
  'form.label.password.new': 'New Password',
  'form.label.password.confirm': 'Confirm Password',
  'action.resetPassword': 'Reset my password',
  'form.error.password.match': "Passwords doesn't match",
  'text.alreadyHaveAnAccount': 'Already have an account?',
  'action.Signin': 'Sign in',
  'text.reselleragent': ' Reseller Agent',
  'form.label.pos_software': 'Restaurant Software',
  'form.placeholder.storeinfo.pos_software': 'Restaurant Software',
  'form.placeholder.pos_software': 'Enter the Restaurant Software',
  'form.error.phone.invalid': 'Invalid phone number',
  'form.placeholder.agent_id': 'Agent ID',
  'text.custommessage.openorder': 'No open orders to show',
  'text.custommessage.closedorder': 'No closed orders to show',
  'text.custommessage.windowsclient': 'No windows client to show',
  'text.custommessage.paymentterminal': 'No payment terminal to show',
  'text.custommessage.activestores': 'No active stores to show',
  'text.custommessage.inactivestores': 'No Inactive stores to show',
  'text.custommessage.demostores': 'No Demo account to show',
  'text.custommessage.userinfo': 'No users to show',
  'action.viewReceipt': 'View Receipt',
  'form.label.select.restaurant_software': ' ',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}

export default async function getMenuData() {
  return [
    {
      title: 'Manage Orders',
      key: 'manage-orders',
      icon: 'fa fa-cutlery',
      url: '/manage/orders',
    },
    {
      title: 'Manage Category',
      key: 'manage-categories',
      icon: 'fe fe-menu',
      url: '/manage/category',
    },
    {
      title: 'Link Product with Category',
      key: 'manage-product-name',
      icon: 'fe fe-eye',
      url: '/manage/product-with-category',
    },

    {
      title: 'Manage Vendors',
      key: 'manage-vendors',
      icon: 'fe fe-user',
      url: '/manage/vendor',
    },

    {
      title: 'Manage Products',
      key: 'manage-products',
      icon: 'fe fe-menu',
      url: '/manage/products',
    },

    {
      title: 'Manage Customers',
      key: 'manage-customers',
      icon: 'fe fe-user',
      url: '/manage/customer',
    },
    {
      title: 'Reports',
      key: 'reports',
      icon: 'fe fe-download',
      url: '/reports',
    },
    {
      title: 'Manage Sliders',
      key: 'manage-sliders',
      icon: 'fa fa-image',
      url: '/manage/sliders',
    },
    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user',
      url: '/manage/user',
    },
    {
      title: 'Manage Bill',
      key: 'manage-bill',
      icon: 'fe fe-user',
      url: '/manage/bill',
    },
  ]
}

import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadproductsinfo,
  loadproductmaster,
  loadproductname,
  addproduct,
  updateproduct,
  changeproductimage,
  loadcategoryfromvenprod,
  loadfilter,
  updatestock,
  saveimages,
  loadproductimages,
  updateimagestatus,
  deleteimage,
} from 'api/productsinfo'
import actions from './actions'

export function* LOAD_ALL_PRODUCTS() {
  const { response } = yield call(loadproductsinfo)
  if (response) {
    const { productsinfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        productsinfodata: productsinfo,
      },
    })
  }
}

export function* FILTER({ payload }) {
  const { response } = yield call(loadfilter, payload)
  if (response) {
    const { productsinfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        productsinfodata: productsinfo,
      },
    })
  }
}

export function* UPDATE_STOCK({ payload }) {
  const { response } = yield call(updatestock, payload)
  if (response) {
    const { productsinfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        productsinfodata: productsinfo,
        selectedData: null,
        displayStockModal: false,
      },
    })
  }
}

export function* LOAD_CATEGORY_PRODUCTS() {
  const { response } = yield call(loadcategoryfromvenprod)
  if (response) {
    const { productcategorylist } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        productcategorydata: productcategorylist,
      },
    })
  }
}

export function* LOAD_PRODUCT_MASTER() {
  const { response } = yield call(loadproductmaster)
  if (response) {
    const { productmasterinfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        productsmasterdata: productmasterinfo,
      },
    })
  }
}

export function* GETPRODUCTNAME({ payload }) {
  const { response } = yield call(loadproductname, payload)
  if (response) {
    const { productnamelist } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        productnamedata: productnamelist,
      },
    })
  }
}

export function* ADD_PRODUCTS({ payload }) {
  const { response } = yield call(addproduct, payload)
  if (response) {
    const { productsinfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        productsinfodata: productsinfo,
        displayProductAddDrawer: false,
      },
    })
  }
}

export function* SAVE_PRODUCT_IMAGES({ payload }) {
  const { response } = yield call(saveimages, payload)
  if (response) {
    const { productImagesList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        ImagesList: productImagesList,
        displayAddImageDrawer: false,
      },
    })
  }
}

export function* LOAD_PRODUCT_IMAGES({ payload }) {
  const { response } = yield call(loadproductimages, payload)
  if (response) {
    const { productImagesList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        ImagesList: productImagesList,
      },
    })
  }
}

export function* UPDATE_PRODUCTS({ payload }) {
  const { response } = yield call(updateproduct, payload)
  if (response) {
    const { productsinfo } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        productsinfodata: productsinfo,
        displayProductUpdateDrawer: false,
      },
    })
  }
}

export function* CHANGE_PRODUCT_IMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(changeproductimage, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { productsinfo } = response.data
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        productsinfodata: productsinfo,
      },
    })
  }
}

export function* UPDATE_IMAGE_STATUS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updateimagestatus, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { productImagesList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        ImagesList: productImagesList,
        displayAddImageDrawer: false,
      },
    })
  }
}

export function* DELETEiMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      deleting: true,
    },
  })
  const { response } = yield call(deleteimage, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      deleting: false,
    },
  })
  if (response) {
    const { productImagesList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        ImagesList: productImagesList,
        displayDeleteImagePopup: false,
        displayAddImageDrawer: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL_PRODUCTS, LOAD_ALL_PRODUCTS),
    takeLatest(actions.LOAD_PRODUCT_MASTER, LOAD_PRODUCT_MASTER),
    takeLatest(actions.GETPRODUCTNAME, GETPRODUCTNAME),
    takeLatest(actions.ADD_PRODUCTS, ADD_PRODUCTS),
    takeLatest(actions.UPDATE_PRODUCTS, UPDATE_PRODUCTS),
    takeLatest(actions.CHANGE_PRODUCT_IMAGE, CHANGE_PRODUCT_IMAGE),
    takeLatest(actions.LOAD_CATEGORY_PRODUCTS, LOAD_CATEGORY_PRODUCTS),
    takeLatest(actions.FILTER, FILTER),
    takeLatest(actions.UPDATE_STOCK, UPDATE_STOCK),
    takeLatest(actions.SAVE_PRODUCT_IMAGES, SAVE_PRODUCT_IMAGES),
    takeLatest(actions.LOAD_PRODUCT_IMAGES, LOAD_PRODUCT_IMAGES),
    takeLatest(actions.UPDATE_IMAGE_STATUS, UPDATE_IMAGE_STATUS),
    takeLatest(actions.DELETEiMAGE, DELETEiMAGE),
  ])
}
